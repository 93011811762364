.rating-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.35rem;

  .clear-rating:hover {
    color: $danger;
  }

  .rating-stars {
    line-height: 1em;

    .empty-stars,
    .filled-stars {
      display: inline-block;
      line-height: 1em;

      .star {
        display: inline-block;
        line-height: 1em;
      }
    }

    .filled-stars {
      -webkit-text-stroke: 0.08em #aaa;

      .star {
        font-size: 0.88em;
        position: relative;
        margin: 0 0.185em;
        top: -0.05em;
      }
    }
  }
}
