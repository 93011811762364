/*
  Subtitles editor
*/

$borderBox: solid 2px #3578bf;

.subtitle-editor{
  td textarea{
    font-size: 12px;
    overflow-y: scroll;
    min-height: 60px !important;
    resize: none;
  }
  #srt-preview {
    min-height: 550px;
  }
  table{

    tr{

      td{
        border-bottom: #7d8293;

        .form-group{
          margin-bottom: 0px !important;
        }
        .count-characters{
          font-size: 11px;
        }
      }
      td:nth-child(1){
        width: 60%;
      }
      td:nth-child(3){
        width: 15%;
      }
      td:nth-child(4){
        width: 0%;
      }
      td:nth-child(5){
        width: 10%;
      }
      td:nth-child(6){
        width: 10%;
      }
    }
  }
  #loader-locution{
    margin: 15px 0px 15px 0px;
    padding: 0px 10px 0px 10px;
    box-sizing: border-box;

    audio{
      width: 100%;
    }
  }
}

.grill-subtitle-selected{
    background: linear-gradient(to left, #b8daff, #ecf3fb);
    border-bottom:  $borderBox;
    border-top:  $borderBox;
}