/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  overflow: hidden;

  .nav-link {
    font-weight: 500;
    color: #333;

    .feather {
      margin-right: 4px;
      color: #999;
    }

    &:hover .feather,
    &.active .feather {
      color: inherit;
    }

    &.active {
      color: $primary;

      & > a {
        color: #fff;
        background: $primary
      }
    }
    .fa{
      min-width: 22px;
      text-align: center;
    }
    &[data-toggle="collapse"] .collapse-icon:before {
      content: fa-content($fa-var-chevron-down);
    }

    &[data-toggle="collapse"].collapsed .collapse-icon:before {
      content: fa-content($fa-var-chevron-right);
    }
  }
}

.sidebar-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 48px; /* Height of navbar */
  height: calc(100vh - 48px);
  padding-top: .5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}