.select2-container {
  /* remove X from locked tag */
  .locked-tag .select2-selection__choice__remove {
    display: none !important;
  }

  .select2-selection__choice {
    @extend .badge;
    @extend .badge-primary;
    @extend .border-0;

    color: color-yiq($primary) !important;
    background-color: $primary !important;
    height: $input-line-height*1em;
    line-height: $input-line-height*1em;

    .select2-selection__choice__remove {
      color: color-yiq($primary) !important;
    }
  }

  ///* I suggest to hide  all selected tags from drop down list */
  //.select2-results__option[aria-selected="true"] {
  //  display: none;
  //}
}
