body {
  font-size: .875rem;
}

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

html.select-museum {
  &, body {
    height: 100%;
  }

  body {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #f5f5f5;
  }

  .select-museum {
    width: 100%;
    max-width: 430px;
    padding: 15px;
    margin: auto;

    h1 {
      text-align: center;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.modal {
  overflow: auto !important;
}
.geo-map-container {
  min-height: 600px;
}
.thumbnail-content {
  height: 50px;
  width: 50px;
  object-fit: cover;
}