.advanced-file-manager {
  .btn {
    &.upload {
      overflow: hidden;
    }
  }
}
.file-manager-preview {
  max-width: 250px;
}
