form {
  textarea.form-control {
    min-height: 150px;
  }

  .form-control.chosen {
    height: auto;
    padding: 0;

    select {
      display: block;
      width: 100%;
    }

    .chosen-container {
      @extend .form-control;

      border: none;
      min-height: calc(#{$input-height} - #{$input-height-border});
    }
  }
}
