.tour_editor,
.section_editor,
.container_editor,
.award_editor,
.notification_editor,
.content_editor {
  .tab-content {
    > .tab-pane {
      // Enable `flex-grow: 1` for decks and groups so that card blocks take up
      // as much space as possible, ensuring footers are aligned to the bottom.
      flex: 1 1 auto;
      padding: $card-spacer-x;

      &.child-form {
        padding-bottom: ($card-spacer-x - $form-group-margin-bottom) !important;
      }
    }
  }

  .card {
    &.is-invalid {
      border-color: $form-feedback-invalid-color;

      .card-img-top {
        position: relative;

        .badge {
          top: - map_get($spacers, 1);
          left: - map_get($spacers, 1);
          position: absolute;
        }
      }
    }

    &.collapsible {
      .card-header {
        .fa {
          transition: .3s transform ease-in-out;
        }

        &.collapsed .fa {
          transform: rotate(-90deg);
        }
      }
    }

    .card-body.child-form {
      padding-bottom: ($card-spacer-x - $form-group-margin-bottom) !important;
    }
  }
}
