/*
 * Navbar
 */

.navbar {
  .dropdown {
    padding: 0;
  }

  .navbar .form-control {
    padding: .75rem 1rem;
    border-width: 0;
    border-radius: 0;
  }

  .nav-link {
    color: rgba(255, 255, 255, 0.5);
  }

  .form-inline a{
    color: #fff;
  }

  .form-control-dark:focus {
    border-color: transparent;
    box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
  }

  .navbar-nav {
    -webkit-box-orient: horizontal;
    flex-direction: row !important;
  }

  /*
   * Utilities
   */

  .border-top {
    border-top: 1px solid #e5e5e5;
  }

  .border-bottom {
    border-bottom: 1px solid #e5e5e5;
  }

  .flag-language {
    width: 22px;
  }

  .dropdown-item:not(:hover){
    color: #FFF;
  }

  .nav-item {
    border-left: solid 1px #343a40;
    margin-left: 12px;
    padding: 0px 8px;
  }
}
